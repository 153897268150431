import React, { useEffect, useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Route, useHistory, useLocation, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { colourScheme, primary, primaryLight, primaryLighter } from './util/colourSchemes';
import { event, pageview } from './util/tracking';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Auth from '@aws-amplify/auth';
import { updateLocalUserState } from './repository/user/user';
import { loginUser } from './repository/user/authState';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import './styles/overrides.css';
import { updatePrivacyAction } from './actions/privacy/updatePrivacyAction';
import Feedback from './pages/Feedback';
import * as Sentry from '@sentry/react';
import green from '@material-ui/core/colors/green';

const Contracts = React.lazy(() => import('./pages/Contracts'));
const RequestedAgreements = React.lazy(() => import('./pages/RequestedAgreements'));
const RespondedAgreements = React.lazy(() => import('./pages/RespondedAgreements'));
const HistoricalAgreements = React.lazy(() => import('./pages/HistoricalAgreements'));
const NoMatch = React.lazy(() => import('./components/NoMatch'));
const ToolbarDrawer = React.lazy(() => import('./components/ToolbarDrawer'));
const Profiles = React.lazy(() => import('./pages/Profiles'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Landing = React.lazy(() => import('./pages/Landing'));
const ContentPage = React.lazy(() => import('./pages/ContentPage'));

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primary
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: primaryLighter,
      main: primaryLight
      // dark: will be calculated from palette.secondary.main,
    },
    success: {
      main: green[500]
    }
  },
  typography: {
    useNextVariants: true
  }
});

function App(props) {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { theme: globalTheme, privacy, acceptPrivacy } = props;

  const initializeData = async () => {
    const userData = await Auth.currentUserInfo();
    const { login, logout, user, visited, wantToGo, search, trips } = props;
    if (userData) {
      setLoading(true);
      await login(userData, visited, wantToGo, search, trips);
    } else if (!userData && user) {
      await logout();
    }
    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener('error', (e) => {
      // prompt user to confirm refresh
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });
    initializeData();
  }, []);

  useEffect(() => {
    pageview();
    history.listen(() => pageview());
  }, [history]);

  useEffect(() => {
    document.body.style.backgroundColor = colourScheme[globalTheme.colourScheme].background;
  }, [globalTheme.colourScheme, globalTheme]);

  return (
    <div
      className="App"
      style={{
        backgroundColor: colourScheme[globalTheme.colourScheme].background,
        background: colourScheme[globalTheme.colourScheme].background
      }}
    >
      <MuiThemeProvider theme={theme}>
        <React.Suspense
          fallback={
            <Grid container style={{ marginTop: '72px' }} justify={`center`} align={`center`}>
              <CircularProgress size={72} />
            </Grid>
          }
        >
          <ToolbarDrawer history={history} location={location}>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={!privacy}
              message={
                'We serve cookies on this site to analyze traffic, remember your preferences, and optimize your experience.'
              }
              action={
                <React.Fragment>
                  <Button
                    onClick={() => {
                      event(undefined, 'privacy', { method: 'more_details' });
                      history.push('/content/privacy');
                    }}
                    color="primary"
                  >
                    More Details
                  </Button>
                  <Button
                    onClick={() => {
                      event(undefined, 'privacy', { method: 'accept' });
                      acceptPrivacy();
                    }}
                    color="primary"
                  >
                    OK
                  </Button>
                </React.Fragment>
              }
            />
            <Switch>
              <Route
                exact
                path="/"
                render={(routeProps) => <Landing history={history} loading={loading} {...routeProps} />}
              />
              <Route
                exact
                path="/influencers"
                render={(routeProps) => <Contracts history={history} loading={loading} {...routeProps} />}
              />
              <Route
                exact
                path="/requested-agreements"
                render={(routeProps) => <RequestedAgreements history={history} loading={loading} {...routeProps} />}
              />
              <Route
                exact
                path="/responded-agreements"
                render={(routeProps) => <RespondedAgreements history={history} loading={loading} {...routeProps} />}
              />
              <Route
                exact
                path="/historical-agreements"
                render={(routeProps) => <HistoricalAgreements history={history} loading={loading} {...routeProps} />}
              />
              <Route path="/content" render={(routeProps) => <ContentPage {...routeProps} />} />
              <Route path="/profile" render={(routeProps) => <Profile history={history} {...routeProps} />} />
              <Route path="/profiles/:id" render={(routeProps) => <Profiles history={history} {...routeProps} />} />
              <Route path="/give-feedback" render={(routeProps) => <Feedback />} />
              <Route render={(routeProps) => <NoMatch globalTheme={globalTheme} />} />
            </Switch>
          </ToolbarDrawer>
        </React.Suspense>
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = (dispatch) => ({
  acceptPrivacy: () => dispatch(updatePrivacyAction(true)),
  login: async (userData) => await loginUser(dispatch, userData),
  logout: async () => await updateLocalUserState(dispatch, null)
});

export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App));
