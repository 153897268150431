import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { batch } from 'react-redux';
import { getUser } from '../../graphql/queries';
import { updateUserAction } from '../../actions/user/updateUserAction';
import { updateUser } from '../../graphql/mutations';

/**
 * Takes the serialized user data and turns it into normal JSON
 *
 * @param {object} graphQlUser The user object that graphql returns from queries
 * @returns {*}
 */
export const deSerializeUser = (graphQlUser) => {
  if (!graphQlUser) {
    console.error('The fetched user data from graphql was undefined.', graphQlUser);
  }
  const {
    id,
    username,
    onlyFansUsername,
    onlyFansCount,
    instagramUsername,
    instagramCount,
    twitterUsername,
    twitterCount,
    email,
    userType,
    profilePicture,
    contracts,
    engagements,
    feedbacks
  } = graphQlUser;

  return {
    id,
    username,
    onlyFansUsername,
    onlyFansCount,
    instagramUsername,
    instagramCount,
    twitterUsername,
    twitterCount,
    email,
    userType,
    profilePicture,
    contracts,
    engagements,
    feedbacks
  };
};

/**
 * Takes a user object and updates the relevant local states in one batch update
 *
 * @param dispatch
 * @param user
 * @returns {Promise<*>}
 */
export const pullRemoteUserState = async (dispatch, user, callerFunction) => {
  try {
    if (!user || !user.id) {
      console.error('Tried to full remote user state for non-existent user', callerFunction);
    }
    const existingUser = await GraphQLAPI.graphql(graphqlOperation(getUser, { id: user.id }));
    user = existingUser.data.getUser;
    await updateLocalUserState(dispatch, user);
  } catch (error) {
    console.error('Failed to pull remote state for user', user);
  }
  return user;
};

/**
 * Takes a user object and updates the relevant local states in one batch update
 *
 * @param {function} dispatch - the function used to dispatch state changes to the redux store
 * @param {object} user - the local user state
 * @returns {Promise<*>}
 */
export const updateLocalUserState = async (dispatch, user) => {
  try {
    // In this case we update only parts of the state we received data for
    batch(() => {
      dispatch(updateUserAction(user));
    });
  } catch (error) {
    console.error('Failed to update local state for user', user);
  }
  return user;
};

/**
 * Gets the remote user data and sets it to the local state
 *
 * @param dispatch
 * @param user
 * @returns {Promise<*>}
 */
export const updateRemoteUserState = async (dispatch, user) => {
  if (user === null) {
    return null;
  }
  try {
    const {
      id,
      username,
      onlyFansUsername,
      onlyFansCount,
      instagramUsername,
      instagramCount,
      twitterUsername,
      twitterCount,
      email,
      userType,
      status,
      updatedAt,
      profilePicture
    } = user;
    const input = {
      id,
      username,
      onlyFansUsername,
      onlyFansCount,
      instagramUsername,
      instagramCount,
      twitterUsername,
      twitterCount,
      email,
      userType,
      status,
      updatedAt,
      profilePicture
    };
    const updatedUser = await GraphQLAPI.graphql(graphqlOperation(updateUser, { input }));
    user = updateLocalUserState(dispatch, updatedUser.data.updateUser);
  } catch (error) {
    console.error('Failed to fetch remotely and set locally latest user data', error, user);
  }
  return user;
};
