import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

class SentryLogging {
  constructor() {
    const environment = process.env.REACT_APP_SENTRY_RELEASE;

    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://04dbe16cf62f4626bc7eaa7bf451e9b8@o457191.ingest.sentry.io/5636537',
        release: environment,
        integrations: [new Integrations.BrowserTracing(), new CaptureConsole({ levels: ['error'] })],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
        whitelistUrls: [/postshoutouts\.com/],
        normalizeDepth: 10 // Or however deep you want your state context to be.
      });
    }
  }

  log(level, message, extras) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        for (const [key, value] of Object.entries(extras)) {
          scope.setExtra(key, value);
        }
        Sentry.captureMessage(message, level);
      });
    } else {
      console[level](message, extras);
    }
  }
}

export default SentryLogging;
