import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { updateLocalUserState, updateRemoteUserState } from './user';
import { setupUserSubscriptionsGraph } from './subscriptions';
import { getUser } from '../../graphql/queries';
import { createUser } from '../../graphql/mutations';

/**
 * Updates the local state of user properties and then pushes the changes to graphql remotely
 *
 * @param dispatch
 * @param sessionUser The full user data object
 */
export const loginUser = async (dispatch, sessionUser) => {
  let existingUser;
  const metaData = {
    email: sessionUser.attributes.email
  };

  try {
    existingUser = await GraphQLAPI.graphql(graphqlOperation(getUser, { id: sessionUser.username }));
  } catch (error) {
    console.error('Failed to fetch user on first login', sessionUser, error);
    return;
  }

  if (existingUser !== undefined && existingUser.data !== undefined && existingUser.data.getUser) {
    try {
      sessionUser = existingUser.data.getUser;
      //TODO:: Only assign profilePicture if user currently has undefined one
      Object.assign(sessionUser, metaData);
    } catch (error) {
      console.error('Failed to deserialize and union properties of user', error, sessionUser, metaData);
    }

    try {
      await updateLocalUserState(dispatch, sessionUser);
      sessionUser = await updateRemoteUserState(dispatch, sessionUser);
    } catch (error) {
      console.error('Failed to update user in login function', sessionUser, error);
    }
  } else {
    sessionUser = {
      id: sessionUser.username,
      username: sessionUser.username,
      userType: 'UNVERIFIED',
      status: 'OPEN',
      onlyFansUsername: null,
      onlyFansCount: null,
      instagramUsername: null,
      instagramCount: null,
      twitterUsername: null,
      twitterCount: null,
      isOnlyFans: 'false',
      isInstagram: 'false',
      isTwitter: 'false',
      isTikTok: 'false',
      isSnapchat: 'false',
      isYoutube: 'false',
      isFacebook: 'false',
      isWeChat: 'false',
      isReddit: 'false',
      isPinterest: 'false'
    };
    Object.assign(sessionUser, metaData);
    try {
      const createdUser = await GraphQLAPI.graphql(graphqlOperation(createUser, { input: sessionUser }));
      sessionUser = createdUser.data.createUser;
    } catch (error) {
      console.error('Failed to create user in login function', sessionUser, error);
    }
  }
  await setupUserSubscriptionsGraph(dispatch, sessionUser);
  window.gtag('config', 'MEASUREMENT_ID', {
    user_id: sessionUser.id
  });
  return sessionUser;
};
