/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract($input: CreateContractInput!, $condition: ModelContractConditionInput) {
    createContract(input: $input, condition: $condition) {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract($input: UpdateContractInput!, $condition: ModelContractConditionInput) {
    updateContract(input: $input, condition: $condition) {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract($input: DeleteContractInput!, $condition: ModelContractConditionInput) {
    deleteContract(input: $input, condition: $condition) {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!, $condition: ModelGroupConditionInput) {
    createGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($input: UpdateGroupInput!, $condition: ModelGroupConditionInput) {
    updateGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($input: DeleteGroupInput!, $condition: ModelGroupConditionInput) {
    deleteGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback($input: CreateFeedbackInput!, $condition: ModelFeedbackConditionInput) {
    createFeedback(input: $input, condition: $condition) {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback($input: UpdateFeedbackInput!, $condition: ModelFeedbackConditionInput) {
    updateFeedback(input: $input, condition: $condition) {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback($input: DeleteFeedbackInput!, $condition: ModelFeedbackConditionInput) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
