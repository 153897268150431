/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      nextToken
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!, $status: ContractStatus!, $createdAt: AWSDateTime!) {
    getContract(id: $id, status: $status, createdAt: $createdAt) {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $id: ID
    $statusCreatedAt: ModelContractPrimaryCompositeKeyConditionInput
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContracts(
      id: $id
      statusCreatedAt: $statusCreatedAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        createdAt
        owner {
          id
          updatedAt
          username
          status
          email
          emailConfirmed
          profilePicture
          userType
          verificationRequested
          feedbacks {
            nextToken
          }
          contracts {
            nextToken
          }
          onlyFansUsername
          onlyFansCount
          instagramUsername
          instagramCount
          twitterUsername
          twitterCount
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          createdAt
        }
        match {
          id
          updatedAt
          username
          status
          email
          emailConfirmed
          profilePicture
          userType
          verificationRequested
          feedbacks {
            nextToken
          }
          contracts {
            nextToken
          }
          onlyFansUsername
          onlyFansCount
          instagramUsername
          instagramCount
          twitterUsername
          twitterCount
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          createdAt
        }
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        onlyFansOwnerImage
        instagramOwnerImage
        twitterOwnerImage
        onlyFansOwnerText
        instagramOwnerText
        twitterOwnerText
        onlyFansMatchImage
        instagramMatchImage
        twitterMatchImage
        onlyFansMatchText
        instagramMatchText
        twitterMatchText
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups($filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks($filter: ModelFeedbackFilterInput, $limit: Int, $nextToken: String) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        status
        description
        reporter {
          id
          updatedAt
          username
          status
          email
          emailConfirmed
          profilePicture
          userType
          verificationRequested
          feedbacks {
            nextToken
          }
          contracts {
            nextToken
          }
          onlyFansUsername
          onlyFansCount
          instagramUsername
          instagramCount
          twitterUsername
          twitterCount
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByFilters = /* GraphQL */ `
  query UsersByFilters(
    $status: UserStatus
    $isOnlyFansIsInstagramIsTwitterIsSnapchatIsYoutubeIsFacebookIsTikTokIsWeChatIsRedditIsPinterest: ModelUserByFiltersCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByFilters(
      status: $status
      isOnlyFansIsInstagramIsTwitterIsSnapchatIsYoutubeIsFacebookIsTikTokIsWeChatIsRedditIsPinterest: $isOnlyFansIsInstagramIsTwitterIsSnapchatIsYoutubeIsFacebookIsTikTokIsWeChatIsRedditIsPinterest
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      nextToken
    }
  }
`;
export const contractsByFilters = /* GraphQL */ `
  query ContractsByFilters(
    $status: ContractStatus
    $isOnlyFansIsInstagramIsTwitterIsSnapchatIsYoutubeIsFacebookIsTikTokIsWeChatIsRedditIsPinterest: ModelContractByFiltersCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractsByFilters(
      status: $status
      isOnlyFansIsInstagramIsTwitterIsSnapchatIsYoutubeIsFacebookIsTikTokIsWeChatIsRedditIsPinterest: $isOnlyFansIsInstagramIsTwitterIsSnapchatIsYoutubeIsFacebookIsTikTokIsWeChatIsRedditIsPinterest
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        createdAt
        owner {
          id
          updatedAt
          username
          status
          email
          emailConfirmed
          profilePicture
          userType
          verificationRequested
          feedbacks {
            nextToken
          }
          contracts {
            nextToken
          }
          onlyFansUsername
          onlyFansCount
          instagramUsername
          instagramCount
          twitterUsername
          twitterCount
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          createdAt
        }
        match {
          id
          updatedAt
          username
          status
          email
          emailConfirmed
          profilePicture
          userType
          verificationRequested
          feedbacks {
            nextToken
          }
          contracts {
            nextToken
          }
          onlyFansUsername
          onlyFansCount
          instagramUsername
          instagramCount
          twitterUsername
          twitterCount
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          createdAt
        }
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        onlyFansOwnerImage
        instagramOwnerImage
        twitterOwnerImage
        onlyFansOwnerText
        instagramOwnerText
        twitterOwnerText
        onlyFansMatchImage
        instagramMatchImage
        twitterMatchImage
        onlyFansMatchText
        instagramMatchText
        twitterMatchText
        updatedAt
      }
      nextToken
    }
  }
`;
