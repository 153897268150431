/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "postshoutouts-20210214170913-hostingbucket-main",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d2s7ecnpb93ze7.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-central-1:86a4f574-31d2-4ca7-a40a-0def3763dc2c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_LmTJrNDLk",
    "aws_user_pools_web_client_id": "794jdssn3i086t02adfqiesm62",
    "oauth": {
        "domain": "postshoutouts-main.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://postshoutouts.com/,http://localhost:3000/",
        "redirectSignOut": "https://postshoutouts.com/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://od7mqy2yyncypbqxm2dobeuekm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "postshoutouts35be4914115841f9a98dcf859985d3cd121246-main",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
