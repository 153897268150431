/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPutUser = /* GraphQL */ `
  subscription OnPutUser($id: ID!) {
    onPutUser(id: $id) {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      updatedAt
      username
      status
      email
      emailConfirmed
      profilePicture
      userType
      verificationRequested
      feedbacks {
        items {
          id
          type
          status
          description
          reporter {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contracts {
        items {
          id
          status
          createdAt
          owner {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          match {
            id
            updatedAt
            username
            status
            email
            emailConfirmed
            profilePicture
            userType
            verificationRequested
            onlyFansUsername
            onlyFansCount
            instagramUsername
            instagramCount
            twitterUsername
            twitterCount
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            createdAt
          }
          isOnlyFans
          isInstagram
          isTwitter
          isSnapchat
          isYoutube
          isFacebook
          isTikTok
          isWeChat
          isReddit
          isPinterest
          onlyFansOwnerImage
          instagramOwnerImage
          twitterOwnerImage
          onlyFansOwnerText
          instagramOwnerText
          twitterOwnerText
          onlyFansMatchImage
          instagramMatchImage
          twitterMatchImage
          onlyFansMatchText
          instagramMatchText
          twitterMatchText
          updatedAt
        }
        nextToken
      }
      onlyFansUsername
      onlyFansCount
      instagramUsername
      instagramCount
      twitterUsername
      twitterCount
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      createdAt
    }
  }
`;
export const onCreateContract = /* GraphQL */ `
  subscription OnCreateContract {
    onCreateContract {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const onUpdateContract = /* GraphQL */ `
  subscription OnUpdateContract {
    onUpdateContract {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const onDeleteContract = /* GraphQL */ `
  subscription OnDeleteContract {
    onDeleteContract {
      id
      status
      createdAt
      owner {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      match {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      isOnlyFans
      isInstagram
      isTwitter
      isSnapchat
      isYoutube
      isFacebook
      isTikTok
      isWeChat
      isReddit
      isPinterest
      onlyFansOwnerImage
      instagramOwnerImage
      twitterOwnerImage
      onlyFansOwnerText
      instagramOwnerText
      twitterOwnerText
      onlyFansMatchImage
      instagramMatchImage
      twitterMatchImage
      onlyFansMatchText
      instagramMatchText
      twitterMatchText
      updatedAt
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFeedback = /* GraphQL */ `
  subscription OnCreateFeedback {
    onCreateFeedback {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFeedback = /* GraphQL */ `
  subscription OnUpdateFeedback {
    onUpdateFeedback {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFeedback = /* GraphQL */ `
  subscription OnDeleteFeedback {
    onDeleteFeedback {
      id
      type
      status
      description
      reporter {
        id
        updatedAt
        username
        status
        email
        emailConfirmed
        profilePicture
        userType
        verificationRequested
        feedbacks {
          items {
            id
            type
            status
            description
            createdAt
            updatedAt
          }
          nextToken
        }
        contracts {
          items {
            id
            status
            createdAt
            isOnlyFans
            isInstagram
            isTwitter
            isSnapchat
            isYoutube
            isFacebook
            isTikTok
            isWeChat
            isReddit
            isPinterest
            onlyFansOwnerImage
            instagramOwnerImage
            twitterOwnerImage
            onlyFansOwnerText
            instagramOwnerText
            twitterOwnerText
            onlyFansMatchImage
            instagramMatchImage
            twitterMatchImage
            onlyFansMatchText
            instagramMatchText
            twitterMatchText
            updatedAt
          }
          nextToken
        }
        onlyFansUsername
        onlyFansCount
        instagramUsername
        instagramCount
        twitterUsername
        twitterCount
        isOnlyFans
        isInstagram
        isTwitter
        isSnapchat
        isYoutube
        isFacebook
        isTikTok
        isWeChat
        isReddit
        isPinterest
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
