import { combineReducers } from 'redux';
import user from './userReducer';
import theme from './themeReducer';
import toolbarState from './toolbarStateReducer';
import privacy from './privacyReducer';

export default combineReducers({
  user,
  theme,
  toolbarState,
  privacy
});
