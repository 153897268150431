import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import { store, persistor as PersistedStore } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import SentryLogging from './util/errorLogging';
import fromEntries from 'object.fromentries';
import 'url-search-params-polyfill';
import awsConfig from './aws-exports';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(',');
console.log(localRedirectSignIn, productionRedirectSignIn);

const [productionRedirectSignOut, localRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut
  }
};

Amplify.configure(updatedAwsConfig);

/** Begin polyfills */
if (!Object.fromEntries) {
  fromEntries.shim();
}
require('es7-object-polyfill');
/** End polyfills */

new SentryLogging();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={PersistedStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// function sendToAnalytics({ id, name, value }) {
//   ga('send', 'event', {
//     eventCategory: 'Web Vitals',
//     eventAction: name,
//     eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     eventLabel: id, // id unique to current page load
//     nonInteraction: true // avoids affecting bounce rate
//   });
// }

// reportWebVitals(sendToAnalytics);
