import ReactGA from 'react-ga';
import { ReactGA4 } from '../react-ga-4';

export const isProd = () => {
  return process.env.NODE_ENV === 'production';
};

export const pageview = () => {
  if (isProd()) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const event = (universalAnalyticsData, GA4EventType, GA4EventParameters) => {
  if (isProd() && GA4EventType && GA4EventParameters) {
    ReactGA4.event(GA4EventType, GA4EventParameters);
  }
};
