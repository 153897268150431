const toolbarStateReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PUT_TOOLBAR_STATE':
      return { ...action.payload };
    default:
      return state;
  }
};

export default toolbarStateReducer;
